import './App.css';
import {BarGuide} from "./components/BarGuide";
import {RoutePicker} from "./components/RoutePicker";

function App() {
    return (
        <BarGuide />
        // <RoutePicker />
    )
}

export default App;
